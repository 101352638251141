import React from 'react';
import { useRouter } from 'next/router';
import { useScrollVisibility } from '@/hooks/useScrollVisibility';
import { useTranslation } from 'react-i18next';
import { tabBarLinks } from '@/components/TabBar/TabBar.constants';
import NoSsr from '@/components/NoSsr/NoSsr';
import { getDataStatAttr } from '@/utils/helpers';
import { useHasUnreadMessages } from '@/utils/pubnub/useHasUnreadMessages';
import { isCurrentPage } from '@/components/TabBar/TabBar.helpers';
import { usePageTopControlsStore } from '@/store/usePageTopControlsStore';
import {
  clearStoredPremiumAdsData,
  clearStoredScrollPositionData,
} from '@/components/ScrollSaver/ScrollSaver.helpers';
import { ROUTES } from '@/constants/routes';
import { useSaveScrollPositionActions } from '@/components/ScrollSaver/ScrollSaver.hooks';
import { useAuthStore } from '@/store/useAuthStore';
import { useBridgeState } from '@/bridge/hooks';
import * as S from './TabBar.styles';

export const TabBar = () => {
  const { t } = useTranslation();
  const { asPath } = useRouter();
  const hasUnreadMessages = useHasUnreadMessages();
  const [isAuthenticated] = useAuthStore(state => [state.isAuthenticated]);
  const saveScrollPositionActions = useSaveScrollPositionActions();
  const { isVisible, isEndOfPage, scrollPos } = useScrollVisibility();
  const topControlsHeight = usePageTopControlsStore(
    state => state.topControlsHeight
  );
  const { isWebView } = useBridgeState();

  const $isVisible =
    isWebView || scrollPos <= topControlsHeight || isVisible || isEndOfPage;
  const isHomePage = asPath === ROUTES.home;

  function saveScrollPosition(href: string) {
    if (href === ROUTES.home) {
      clearStoredScrollPositionData();
      clearStoredPremiumAdsData();
      return;
    }

    saveScrollPositionActions();
  }

  return (
    <NoSsr>
      <S.TabBarBoxForSize />
      <S.TabBarBox $isVisible={$isVisible}>
        {tabBarLinks(t, isAuthenticated, asPath).map(link => {
          return (
            <S.TabBarLink
              $isDisabled={link.isDisabled}
              aria-disabled={link.isDisabled}
              tabIndex={link.isDisabled ? -1 : undefined}
              key={link.iconName}
              href={link.href}
              onClick={() => saveScrollPosition(link.href)}
              {...(link.href === ROUTES.home && isHomePage
                ? // make it this way to trigger reload on the home page
                  {
                    as: 'a',
                    'data-testid': link.dataStat,
                  }
                : getDataStatAttr({ dataStat: link.dataStat }))}
            >
              <S.TabBarLinkIcon
                $iconName={link.iconName}
                $side={link.isBig ? 45 : 20}
                $isActive={isCurrentPage(link.href, asPath)}
              >
                {link.href === '/messages' && hasUnreadMessages && (
                  <S.TabBarUnreadIcon />
                )}
              </S.TabBarLinkIcon>
              <S.TabBarLinkText $isActive={isCurrentPage(link.href, asPath)}>
                {link.text}
              </S.TabBarLinkText>
            </S.TabBarLink>
          );
        })}
      </S.TabBarBox>
    </NoSsr>
  );
};
